<template>
  <main>
    <b-tabs>
      <b-tab title="Informasi Produk">
        <b-overlay :show="loadingInfoBarang">
          <b-card-actions :no-actions="true">
            <validation-observer ref="form">
              <b-row class="mb-2">
                <b-col md="12" sm="12">
                  <b-alert variant="primary" show>
                    <h2 class="alert-heading">
                      Informasi Produk
                    </h2>
                  </b-alert>
                </b-col>
                <b-col md="6" sm="12">
                  <b-form-group label="Nama Produk" label-for="">
                    <validation-provider #default="{ errors }" name="nama" :rules="{ required: true }">
                      <b-form-input :state="errors.length > 0 ? false : null" v-model="form.nama" placeholder="Ex: Sarung Tangan Medis" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6" sm="12">
                  <b-form-group label="Tipe Produk">
                    <validation-provider #default="{ errors }" name="product_type" :rules="{ required: false }">
                      <b-form-select
                        v-model="form.product_type"
                        :options="[
                          { value: 'barang', text: 'Barang' },
                          { value: 'jasa', text: 'Jasa' },
                        ]"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12" md="6">
                  <b-form-group label="Kategori">
                    <validation-provider #default="{ errors }" name="kategori" :rules="{ required: true }">
                      <!-- <v-select v-model="form.id_kategori" :options="kategoriOptions" :reduce="(option) => option.value" label="text" /> -->
                      
                        <b-input-group>
                        <v-select v-model="form.id_kategori" :options="kategoriOptions" :reduce="(option) => option.value"
                          label="text" placeholder="Silakan Pilih" />
                        <b-input-group-append>
                          <b-button variant="outline-success" @click="showModalKategori = true"><feather-icon
                              icon="PlusIcon" /></b-button>
                        </b-input-group-append>
                      </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-modal v-model="showModalKategori" cancel-variant="secondary" ok-title="Tambah" cancel-title="Batal"
                    centered title="Form Tambah Kategori">
                    <b-form>
                      <b-form-group>
                        <label for="kategori">Kategori</label>
                        <b-form-input v-model="formkategori" id="kategori" placeholder="Nama kategori" />
                      </b-form-group>
                    </b-form>

                    <template #modal-footer>
                      <section class="d-flex justify-content-end align-items-center">
                        <b-button size="sm" class="mr-1" variant="success" @click="submitKategori">
                          Tambah
                        </b-button>
                        <b-button size="sm" variant="danger" @click="showModalKategori = false">
                          Keluar
                        </b-button>
                      </section>
                    </template>
                  </b-modal>
                </b-col>
                <b-col sm="12" md="6">
                  <b-form-group label="Satuan ">
                    <validation-provider #default="{ errors }" name="satuan" :rules="{ required: false }">
                      <!-- <v-select v-model="form.id_satuan" :options="satuanOptions" :reduce="(option) => option.value" label="text">
                        <template #search="{ attributes, events }">
                          <input class="vs__search" :required="!form.id_satuan" v-bind="attributes" v-on="events" />
                        </template>
                      </v-select> -->
                      <b-input-group>
                        <v-select v-model="form.id_satuan" :options="satuanOptions" :reduce="(option) => option.value"
                          label="text">
                          <template #search="{ attributes, events }">
                            <input class="vs__search" :required="!form.id_satuan" v-bind="attributes" v-on="events" />
                          </template>
                        </v-select>
                        <b-input-group-append>
                          <b-button variant="outline-success" @click="showModalSatuan = true"><feather-icon
                              icon="PlusIcon" /></b-button>
                        </b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-modal v-model="showModalSatuan" cancel-variant="secondary" ok-title="Tambah" cancel-title="Batal"
                    centered title="Form Tambah Satuan">
                    <b-form>
                      <b-form-group>
                        <label for="satuan">Satuan</label>
                        <b-form-input v-model="formsatuan" id="satuan" placeholder="Nama satuan" />
                      </b-form-group>
                    </b-form>

                    <template #modal-footer>
                      <section class="d-flex justify-content-end align-items-center">
                        <b-button size="sm" class="mr-1" variant="success" @click="submitSatuan">
                          Tambah
                        </b-button>
                        <b-button size="sm" variant="danger" @click="showModalSatuan = false">
                          Keluar
                        </b-button>
                      </section>
                    </template>
                  </b-modal>
                </b-col>
                <b-col md="6" sm="12">
                  <b-form-group label="Produk Konsinyasi?">
                    <validation-provider #default="{ errors }" name="is_consignment" :rules="{ required: false }">
                      <b-form-select
                        v-model="form.is_consignment"
                        :options="[
                          { value: 1, text: 'Ya' },
                          { value: 0, text: 'Tidak' },
                        ]"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6" sm="12">
                  <b-form-group label="Kondisi?">
                    <validation-provider #default="{ errors }" name="kondisi" :rules="{ required: false }">
                      <b-form-select
                        v-model="form.kondisi"
                        :options="[
                          { value: 1, text: 'Baru' },
                          { value: 2, text: 'Bekas' },
                        ]"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12" sm="12">
                  <b-form-group label="Deskripsi">
                    <validation-provider #default="{ errors }" name="deskripsi" :rules="{ required: false }">
                      <!-- <b-form-input :state="errors.length > 0 ? false : null" v-model="form.deskripsi" placeholder="Deskripsi" /> -->
                      <b-form-textarea v-model="form.deskripsi"></b-form-textarea>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row class="mb-2">
                <b-col md="12" sm="12">
                  <b-alert variant="primary" show>
                    <h2 class="alert-heading">
                      Varian Produk
                    </h2>
                  </b-alert>
                </b-col>
                <b-col sm="12" md="12">
                  <div class="d-flex justify-content-center">
                    <div class="table table-responsive">
                      <b-table-simple small class="table table-bordered table-responsive">
                        <b-thead>
                          <b-tr>
                            <b-th>Kode</b-th>
                            <b-th>Varian</b-th>
                            <b-th>Harga Dasar</b-th>
                            <b-th>No. Batch</b-th>
                            <b-th>Expired Date</b-th>
                            <b-th style="width: 3%"></b-th>
                          </b-tr>
                        </b-thead>
                        <b-tbody>
                          <b-tr v-for="(item, i) in form.variants" :key="i">
                            <b-td>
                              <b-form-input placeholder="Ex: 23213" v-model="item.kode" required />
                            </b-td>
                            <b-td>
                              <b-form-input placeholder="Ex: Ukuran XL" v-model="item.varian" required />
                            </b-td>
                            <b-td>
                              <b-input-group prepend="Rp." class="input-group-merge">
                                <b-form-input v-model="item.harga_dasar" @keyup="doFormatRupiah(i)" placeholder="Harga Dasar" required />
                              </b-input-group>
                            </b-td>
                            <b-td>
                              <b-form-input placeholder="No. Batch" v-model="item.no_batch" required />
                            </b-td>
                            <b-td>
                              <b-form-input type="date" placeholder="Expired Date" v-model="item.expire_date" required />
                            </b-td>
                            <b-td>
                              <section v-if="i == 0 && !$route.params.id">
                                <b-button
                                  @click.prevent="
                                    form.variants.push({
                                      varian: null,
                                      kode: null,
                                      harga_dasar: 0,
                                      no_batch: 0,
                                      expired_date: null,
                                    })
                                  "
                                  variant="primary"
                                  size="sm"
                                >
                                  <feather-icon icon="PlusIcon" />
                                </b-button>
                              </section>
                              <section v-else-if="i > 0 && !$route.params.id">
                                <b-button @click.prevent="removeVar(i)" variant="flat-danger" size="sm">
                                  <feather-icon icon="XIcon" />
                                </b-button>
                              </section>
                            </b-td>
                          </b-tr>
                        </b-tbody>
                      </b-table-simple>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" sm="12">
                  <b-alert variant="primary" show>
                    <h4 class="alert-heading">
                      Pengiriman (tidak wajib)
                    </h4>
                  </b-alert>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="3" sm="6">
                  <b-form-group label="Berat">
                    <validation-provider #default="{ errors }" name="berat" :rules="{ required: false }">
                      <b-input-group append="gram">
                        <b-form-input type="number" :state="errors.length > 0 ? false : null" v-model="form.berat"
                          placeholder="1000" />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3" sm="6">
                  <b-form-group label="Panjang">
                    <validation-provider #default="{ errors }" name="panjang" :rules="{ required: false }">
                      <b-input-group append="cm">
                        <b-form-input type="number" :state="errors.length > 0 ? false : null" v-model="form.panjang"
                          placeholder="100" />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- </b-row>
              <b-row> -->
                <b-col md="3" sm="6">
                  <b-form-group label="Lebar">
                    <validation-provider #default="{ errors }" name="lebar" :rules="{ required: false }">
                      <b-input-group append="cm">
                        <b-form-input type="number" :state="errors.length > 0 ? false : null" v-model="form.lebar"
                          placeholder="10" />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="3" sm="6">
                  <b-form-group label="Tinggi">
                    <validation-provider #default="{ errors }" name="tinggi" :rules="{ required: false }">
                      <b-input-group append="cm">
                        <b-form-input type="number" :state="errors.length > 0 ? false : null" v-model="form.tinggi"
                          placeholder="10" />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="6" sm="6">
                  <b-form-group label="Minimal Pesanan">
                    <validation-provider #default="{ errors }" name="min_pesanan" :rules="{ required: false }">

                      <b-form-input type="number" :state="errors.length > 0 ? false : null" v-model="form.min_pesanan"
                        placeholder="Minimal Pesanan" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6" sm="6">
                  <b-form-group label="Asuransi Pengiriman">
                    <validation-provider #default="{ errors }" name="asuransi_pengiriman" :rules="{ required: false }">
                      <b-form-select v-model="form.asuransi_pengiriman" :options="[
                        { value: 1, text: 'Wajib' },
                        { value: 2, text: 'Opsi' },
                      ]" :state="errors.length > 0 ? false : null" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="12" sm="12">
                  <b-form-group label="Layanan Ekspedisi">
                    <validation-provider #default="{ errors }" name="layanan_ekspedisi" :rules="{ required: false }">
                      <v-select v-model="form.layanan_ekspedisi" multiple :options="layananEkspedisiOptions"
                        :reduce="(option) => option.value" label="text">
                        <template #search="{ attributes, events }">
                          <input class="vs__search" :required="!form.layanan_ekspedisi" v-bind="attributes"
                            v-on="events" />
                        </template>
                      </v-select>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              
              <b-row>
                <b-col md="12" sm="12">
                  <b-alert variant="primary" show>
                    <h4 class="alert-heading">
                      Lain-lain
                    </h4>
                  </b-alert>
                </b-col>
              </b-row>
              <b-row>
                <b-col :md="form.is_pre_order == 1 ? '6' : '12'" sm="6">
                  <b-form-group label="Apakah Produk PreOrder?">
                    <validation-provider #default="{ errors }" name="is_pre_order" :rules="{ required: false }">
                      <b-form-select
                        v-model="form.is_pre_order"
                        :options="[
                          { value: 1, text: 'Ya' },
                          { value: 0, text: 'Tidak' },
                        ]"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col md="6" sm="6" v-if="form.is_pre_order == 1">
                  <b-form-group label="Lama Waktu PreOrder">
                    <validation-provider #default="{ errors }" name="pre_order_day" :rules="{ required: false }">
                      <b-input-group class="input-group-merge">
                        <b-form-input type="number" v-model="form.pre_order_day" placeholder="Lama"></b-form-input>
                        <b-input-group-append is-text>Hari</b-input-group-append>
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col md="12" sm="12">
                  <b-form-group label="Perlihatkan ke cabang lain?">
                    <validation-provider #default="{ errors }" name="show_to_others" :rules="{ required: false }">
                      <b-form-select
                        v-model="form.show_to_others"
                        :options="[
                          { value: 1, text: 'Ya' },
                          { value: 0, text: 'Tidak' },
                        ]"
                        :state="errors.length > 0 ? false : null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
              <div class="d-flex justify-content-end">
                <b-button @click.prevent="submit" variant="primary">
                  {{ label }}
                </b-button>
              </div>
            </validation-observer>
          </b-card-actions>
        </b-overlay>
      </b-tab>
      <b-tab v-if="canManagePrice" title="Pengaturan Harga">
        <b-card>
          <b-table
            striped
            hover
            bordered
            :fields="[
              { key: 'no', label: 'No' },
              { key: 'nama', label: 'Jenis Harga' },
              { key: 'harga', label: 'Harga' },
            ]"
            :items="priceList"
            class="mb-2"
          >
            <template #cell(no)="{ index }">
              {{ index + 1 }}
            </template>
            <template #cell(harga)="{ item }">
              <b-input-group prepend="Rp." class="input-group-merge">
                <b-form-input :state="errors.length > 0 ? false : null" type="number" v-model="item.harga" placeholder="Harga Dasar" />
              </b-input-group>
              <!-- <b-form-input type="number" v-model="item.harga"></b-form-input> -->
            </template>
          </b-table>
          <div class="d-flex justify-content-end">
            <b-button variant="primary" @click.prevent="saveHarga">Simpan</b-button>
          </div>
        </b-card>
      </b-tab>
      <b-tab v-if="canManagePrice" title="Konversi Satuan">
        <b-card>
          <b-row>
            <b-col md="2" sm="4" class="my-1">
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" v-b-modal.modal-tambah variant="primary">
                <!-- v-if="allowCreate()" -->
                <feather-icon icon="PlusIcon" class="mr-50" />
                Tambah
              </b-button>
              <!-- v-if="allowCreate() || allowUpdate()" -->
              <b-modal v-model="showModal" id="modal-tambah" cancel-variant="secondary" ok-title="Tambah" cancel-title="Batal" centered title="Form Tambah">
                <b-form>
                  <b-form-group>
                    <label for="satuan">Pilih Satuan yang akan di konversikan</label>
                    <b-form-select v-model="form2.id_satuan" :options="id_satuan" label="satuan" />
                  </b-form-group>
                  <b-form-group>
                    <label for="nilai">Nilai</label>

                    <b-input-group prepend="" class="input-group-merge">
                      <b-form-input :state="errors.length > 0 ? false : null" type="number" v-model="form2.nilai" placeholder="Harga Dasar" />
                    </b-input-group>
                  </b-form-group>
                </b-form>

                <template #modal-footer>
                  <section class="d-flex justify-content-end align-items-center">
                    <b-button size="sm" class="mr-1" variant="success" @click="saveKonversi"> Simpan </b-button>
                    <b-button size="sm" class="mr-1" variant="primary" @click="resetForm"> Reset </b-button>
                    <b-button size="sm" variant="danger" @click="showModal = false"> Keluar </b-button>
                  </section>
                </template>
              </b-modal>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-table id="table2" striped small hover responsive :items="items" :fields="fields2">
                <template #cell(id_barang)="data">
                  {{ getBarangName(data.item) }}
                </template>
                <template #cell(nilai)="{ item }">
                  {{ formatRupiah(item.nilai) }}
                </template>

                <template #cell(no)="{ index }">
                  {{ index + 1 }}
                </template>

                <template #cell(actions)="row">
                  <b-button v-b-tooltip.hover.right="'Ubah'" size="sm" @click="edit(row.item)" class="mr-1" variant="outline-info"> <feather-icon icon="EditIcon" /> </b-button
                  ><b-button v-b-tooltip.hover.right="'Hapus'" size="sm" @click="remove(row.item)" class="" variant="outline-danger">
                    <feather-icon icon="TrashIcon" />
                  </b-button>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-card>
      </b-tab>
    </b-tabs>
  </main>
</template>
<script>
import Ripple from "vue-ripple-directive";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.us";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BInputGroup,
  BOverlay,
  VBTooltip,
  BDropdown,
  BDropdownItem,
  BAvatar,
  BBadge,
  BModal,
  BPagination,
  BInputGroupPrepend,
  BInputGroupAppend,
  BForm,
  BFormCheckbox,
  BTabs,
  BTab,
  BCard,
  BTable,
  BContainer,
  BRow,
  BCol,
  BButton,
  BFormGroup,
  BFormSelect,
  BFormInput,
  BFormFile,
  BFormTextarea,
  BAlert,
    BTableSimple,
    BTh, BTd, BTr, BThead, BTfoot, BTbody,
} from "bootstrap-vue";
import { getSubject } from "../../../libs/acl/utils";
import vSelect from "vue-select";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", {
  ...required,
  message: "This field is required",
});

export default {
  components: {
    BAlert,
    BInputGroup,
    BTableSimple,
    BTh, BTd, BTr, BThead, BTfoot, BTbody,
    BOverlay,
    Cleave,
    BTabs,
    BTab,
    BTable,
    BCard,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCardActions,
    BContainer,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BFormFile,
    BFormTextarea,
    VBTooltip,
    BDropdown,
    BDropdownItem,
    BAvatar,
    BBadge,
    BModal,
    BPagination,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormCheckbox,
    BForm,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data: () => ({
    loadingInfoBarang: false,
    label: "Simpan",
    konsumenTypes: [],
    selected: null,
    priceList: [],
    options2: {
      number: {
        numeral: true,
        numeralThousandsGroupStyle: "thousand",
      },
    },
    file: null,
    form: {
      nama: null,
      product_type: 'barang',
      jenis: 1,
      jenis_obat: null,
      generik: null,
      narkotik: null,
      klasifikasi_usia: null,
      jenis_penyakit: null,
      id_kategori: null,
      id_satuan: null,
      milik: null,
      deskripsi: null,
      kelompok_id: null,
      kondisi: 1,
      min_pesanan: 1,
      berat: 0,
      panjang: 0,
      lebar: 0,
      tinggi: 0,
      asuransi_pengiriman: 1,
      layanan_ekspedisi: null,
      is_consignment: 0,
      is_pre_order: 1,
      pre_order_day: 0,
      show_to_others: 1,
      variants: [
        {
          kode: null,
          varian: null,
          harga_dasar: 0,
          no_batch: 0,
          expire_date: 0,
        },
      ],
    },
    canManagePrice: false,
    jenisObatOptions: [
      {
        value: 1,
        text: "Dalam",
      },
      {
        value: 2,
        text: "Luar",
      },
    ],
    selectedSatuan: null,
    selectedKategori: null,
    kategoriOptions: [],
    jenisPenyakitOptions: [],
    kelompokOptions: [],
    layananEkspedisiOptions: [],
    satuanOptions: [],
    showModalEdit: false,
    showModal: false,
    id: null,
    form2: {
      nilai: 0,
      id_satuan: null,
    },
    id_barang: [],
    id_satuan: [],
    perPage: 10,
    pageOptions: [10, 20, 50],
    totalRows: 1,
    currentPage: 1,
    sortBy: "",
    sortDesc: false,
    sortDirection: "asc",
    filter: null,
    filterOn: [],
    infoModal: {
      id: "info-modal",
      title: "",
      content: "",
    },
    fields2: [
      {
        key: "no",
        label: "No",
      },
      // { key: "id", label: "ID"},
      // { key: "nilai", label: "Nama Gudang", sortable: true },
      { key: "satuan.satuan", label: "dari satuan" },
      { key: "nilai", label: "Jumlah" },
      { key: "id_barang", label: "Satuan Terkecil" },
      // { key: "", label: "ke satuan" },
      // { key: "barang.nama", label: "barang", sortable: true },
      // { key: "alamat", label: "alamat" },
      // { key: "keterangan", label: "Keterangan" },
      { key: "actions", label: "Aksi" },
    ],
    items: [],
    status: [
      {
        1: "Current",
        2: "Professional",
        3: "Rejected",
        4: "Resigned",
        5: "Applied",
      },
      {
        1: "light-primary",
        2: "light-success",
        3: "light-danger",
        4: "light-warning",
        5: "light-info",
      },
    ],
    showModalKategori: false,
    formkategori: null,
    showModalJenisPenyakit: false,
    formjenispenyakit: null,
    showModalKelompok: false,
    formkelompok: null,
    showModalSatuan: false,
    formsatuan: null,
  }),

  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields.filter((f) => f.sortable).map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    submitKategori() {
      if (this.formkategori == null || this.formkategori == '') {
        this.displayWarning({
          text: "Kategori harap diisi !",
        });
        return false
      }
      let payload = {
        kategori: this.formkategori
      }
      this.$store.dispatch('kategori/save', [payload])
        .then(() => {
          this.formkategori = null
          this.showModalKategori = false
          this.getKategori()
          this.displaySuccess({
            text: "Kategori berhasil ditambahkan !",
          });
        })
        .catch(e => console.error(e))
    },
    submitJenisPenyakit() {
      if (this.formjenispenyakit == null || this.formjenispenyakit == '') {
        this.displayWarning({
          text: "Jenis Penyakit harap diisi !",
        });
        return false
      }
      let payload = {
        jenis_penyakit: this.formjenispenyakit
      }
      this.$store.dispatch('jenispenyakit/save', [payload])
        .then(() => {
          this.formjenispenyakit = null
          this.showModalJenisPenyakit = false
          this.getJenisPenyakit()
          this.displaySuccess({
            text: "Jenis Penyakit berhasil ditambahkan !",
          });
        })
        .catch(e => console.error(e))
    },
    submitSatuan() {
      if (this.formsatuan == null || this.formsatuan == '') {
        this.displayWarning({
          text: "Satuan harap diisi !",
        });
        return false
      }
      let payload = {
        satuan: this.formsatuan
      }
      this.$store.dispatch('satuan/save', [payload])
        .then(() => {
          this.formsatuan = null
          this.showModalSatuan = false
          this.getSatuan()
          this.displaySuccess({
            text: "Satuan berhasil ditambahkan !",
          });
        })
        .catch(e => console.error(e))
    },
    submitKelompok() {
      if (this.formkelompok == null || this.formkelompok == '') {
        this.displayWarning({
          text: "Kelompok harap diisi !",
        });
        return false
      }
      let payload = {
        kelompok: this.formkelompok
      }
      this.$store.dispatch('kelompok/save', [payload])
        .then(() => {
          this.formkelompok = null
          this.showModalKelompok = false
          this.getKelompok()
          this.displaySuccess({
            text: "Kelompok berhasil ditambahkan !",
          });
        })
        .catch(e => console.error(e))
    },
    removeVar(i, item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Varian ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.form.variants.splice(i, 1);
        }
      });
    },
    doFormatRupiah(i) {
      this.form.variants[i].harga_dasar = this.formatRupiah(this.form.variants[i].harga_dasar);
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async saveHarga() {
      const thereEmptyHarga = this.priceList.some((price) => price.harga < 1);
      if (thereEmptyHarga) {
        this.displayError({
          message: "Harga tidak boleh 0",
        });
        return false;
      }
      const payloads = [];
      this.priceList.map((price) => {
        let payload = {
          barang_id: price.barang_id,
          tipe_konsumen_id: price.id,
          harga: price.harga,
        };
        if (price.harga_id) {
          payload.id = price.harga_id;
        }

        payloads.push(payloxad);
      });

      try {
        await this.$store.dispatch("harga-barang/save2", payloads);
        this.displaySuccess({
          message: "Harga berhasil disimpan",
        });
      } catch (e) {
        this.displayError(e);
        return false;
      }
    },

    submit() {
      this.$refs.form.validate().then(async (success) => {
        if (success) {
          // validation for varian
          const thereEmptyVarian = this.form.variants.filter((item) => item.varian == null || item.kode == null);

          if (thereEmptyVarian.length > 0) {
            this.displayError({
              message: "Harap isi varian dan kode yang kosong",
            });
            return false;
          }
          let payload = [];
          this.form.variants.map((item) => {
            let barangName = `${this.form.nama}`;
            let obj = {
              nama: barangName,
              kode: item.kode,
              varian: item.varian,
              harga_dasar: this.unFormatRupiah(item.harga_dasar),
              // no_batch: item.no_batch,
              expire_date: item.expire_date,
              id_kategori: this.form.id_kategori,
              id_satuan: this.form.id_satuan,
              jenis: this.form.jenis,
              product_type: this.form.product_type,
              jenis_obat: this.form.jenis_obat,
              narkotik: this.form.narkotik,
              generik: this.form.generik,
              jenis_penyakit: this.form.jenis_penyakit,
              layanan_ekspedisi: this.form.layanan_ekspedisi,
              klasifikasi_usia: this.form.klasifikasi_usia,
              milik: this.form.milik,
              deskripsi: this.form.deskripsi,
              kelompok_id: this.form.kelompok_id,
              kondisi: this.form.kondisi,
              min_pesanan: this.form.min_pesanan,
              berat: this.form.berat,
              panjang: this.form.panjang,
              lebar: this.form.lebar,
              tinggi: this.form.tinggi,
              asuransi_pengiriman: this.form.asuransi_pengiriman,
              is_consignment: this.form.is_consignment,
              is_pre_order: this.form.is_pre_order,
              pre_order_day: this.form.pre_order_day,
              show_to_others: this.form.show_to_others,
            };
            if (this.isCabang) {
              obj.member_id = this.user.karyawan.id;
            }
            if (this.$route.params.id) {
              obj.id = this.$route.params.id;
            }
            payload.push(obj);
          });

          // console.log("payload", payload);

          try {
            this.loadingInfoBarang = true;
            this.label = "Loading...";
            const barang = await this.$store.dispatch("barang/save2", payload);
            this.label = "Simpan";
            const message = this.$route.params.id ? "Barang berhasil diperbaharui" : "Barang berhasli ditambahkan";
            this.displaySuccess({
              message,
            });
            // console.log("barang", barang);
            setTimeout(() => this.$router.push(`/barang/edit/${barang.response.id}?tab=konversi`), 1000);

            const action = this.$route.params.id ? "update" : "create";
            this.loadingInfoBarang = false;

            this.checkPermission(action);
            this.getDataKonversi();
            this.getDatabarang();
            this.getStatus();
          } catch (e) {
            this.loadingInfoBarang = false;
            this.label = "Simpan";
            this.displayError(e);
            return false;
          }
        }
      });
    },
    async getDataKonversi() {
      this.$store
        .dispatch("konversi/getData", { id_barang: this.$route.params.id })
        .then(() => {
          this.items = this.$store.state.konversi.datas;
          this.totalRows = this.items.length;
        })
        .catch((e) => console.error(e));
    },
    getDatabarang() {
      this.$store
        .dispatch("barang/getDataV2", {})
        .then(() => {
          let ter = JSON.parse(JSON.stringify(this.$store.state.barang.datas));
          ter.map((item) => {
            item.value = item.id;
            item.text = item.nama + " - " + item.satuan.satuan;
          });
          this.id_barang = ter;
        })
        .catch((e) => console.error(e));
    },
    getBarangName(data) {
      let find = this.id_barang.find((item) => item.id == data.id_barang);
      return find ? find.satuan.satuan : "-";
    },
    async getSatuan() {
      let satuan = await this.$store.dispatch("satuan/getData");
      satuan = JSON.parse(JSON.stringify(satuan));
      let newSatuan = [];
      satuan.map((item) => {
        newSatuan.push({
          text: item.satuan,
          value: item.id,
        });
      });
      this.id_satuan = newSatuan;
      this.satuanOptions = newSatuan;
    },
    async getKategori() {
      let kategori = await this.$store.dispatch("kategori/getData");
      kategori = JSON.parse(JSON.stringify(kategori));
      let newKategori = [];
      kategori.map((item) => {
        newKategori.push({
          text: item.kategori,
          value: item.id,
        });
      });

      this.kategoriOptions = newKategori;
    },
    async getJenisPenyakit() {
      let jenisPenyakit = await this.$store.dispatch("jenispenyakit/getData");
      jenisPenyakit = JSON.parse(JSON.stringify(jenisPenyakit));
      let newjenisPenyakit = [];
      jenisPenyakit.map((item) => {
        newjenisPenyakit.push({
          text: item.jenis_penyakit,
          value: item.id,
        });
      });

      this.jenisPenyakitOptions = newjenisPenyakit;
    },
    async getKelompok() {
      let kelompok = await this.$store.dispatch("kelompok/getData");
      kelompok = JSON.parse(JSON.stringify(kelompok));
      let newkelompok = [];
      kelompok.map((item) => {
        newkelompok.push({
          text: item.kelompok,
          value: item.id,
        });
      });

      this.kelompokOptions = newkelompok;
    },
    async getLayananEkspedisi() {
      let layananEkspedisi = await this.$store.dispatch("layananekspedisi/getData");
      layananEkspedisi = JSON.parse(JSON.stringify(layananEkspedisi));
      let newlayananEkspedisi = [];
      layananEkspedisi.map((item) => {
        newlayananEkspedisi.push({
          text: item.layanan_ekspedisi,
          value: item.id,
        });
      });

      this.layananEkspedisiOptions = newlayananEkspedisi;
    },
    checkPermission(action) {
      const subject = getSubject(this.$route);

      if (!this.$can(action, subject)) {
        this.$router.push("/not-authorized");
      }
    },
    async getStatus() {
      const currentMenu = await this.currentMenu(this.$route.meta.parent);

      // get status for check current user can manage harga
      const listStatus = await this.$store.dispatch("statusrole/getData", {
        menu_id: currentMenu.id,
        level_id: this.user.level.id,
      });
      const canManagePrice = listStatus.find((status) => status.data_status == 1);
      this.canManagePrice = canManagePrice && this.$route.params.id ? true : false;
    },
    async getTypeKonsumen() {
      const tipeKonsumen = await this.$store.dispatch("tipekonsumen/getData");
      tipeKonsumen.map(async (tipe) => {
        tipe.harga = 0;
        tipe.harga_id = null;
        tipe.barang_id = this.$route.params.id;
        const harga = await this.$store.dispatch("harga-barang/getData", {
          where: {
            barang_id: this.$route.params.id,
            tipe_konsumen_id: tipe.id,
          },
        });

        if (harga && harga.length > 0) {
          tipe.harga_id = harga[0].id;
          tipe.harga = harga[0].harga;
        }
      });

      this.priceList = tipeKonsumen;
    },
    resetForm() {
      this.form2 = {
        id_barang: this.$route.params.id,
        nilai: 0,
        id_satuan: 0,
      };
    },
    saveKonversi() {
      if (this.form2.nilai == null || this.form2.nilai == "") {
        this.displayError(e);
        return false;
      }
      if (this.id) {
        this.form2.id = this.id;
      }
      const payload = this.form2;
      this.$store
        .dispatch("konversi/save", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.getDataKonversi();
          this.displaySuccess({
            message: "Konversi harga berhasil disimpan!",
          });
          this.resetForm();
          this.showModal = false;
          this.showModalEdit = false;
        })
        .catch((e) => {
          //   this.pesanGagalHapus();
          this.displayError(e);
          return false;
        });
      // this.pesanGagalSimpan()
    },
    edit(item) {
      let { satuan, barang } = item;
      this.id = item.id;
      this.form2 = item;
      this.form2.nilai = item.nilai;
      if (satuan) this.form2.id_satuan = satuan.id;
      if (barang) this.form2.id_barang = barang.id;
      this.showModal = true;
    },
    remove(item) {
      this.$swal({
        title: "Anda yakin?",
        text: `Data konversi harga ini akan dihapus`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Batal",
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          item.fungsi = 1; // soft delete
          this.$store
            .dispatch("konversi/save", [item])
            .then(() => {
              this.getDataKonversi();
              this.displaySuccess({
                message: "Konversi harga berhasil dihapus!",
              });
            })
            .catch((e) => {
              //   this.pesanGagalHapus();
              this.displayError(e);
              return false;
            });
        }
      });
    },
  },
  async mounted() {
    this.getDataKonversi();
    this.getDatabarang();
    this.getStatus();
  },
  async created() {
    const action = this.$route.params.id ? "update" : "create";

    this.checkPermission(action);

    this.getTypeKonsumen();

    this.getKategori();

    this.getJenisPenyakit();

    this.getKelompok();

    this.getLayananEkspedisi();

    this.getSatuan();

    // this.getStatus();

    if (this.$route.params.id) {
      let barang = this.$store.getters["barang/getBarang"](this.$route.params.id);
      if (!barang) {
        barang = await this.$store.dispatch("barang/getDataById", this.$route.params.id);
      }

      // let ter = JSON.parse(JSON.stringify(barang));
      //     ter.map((item) => {
      //         item.value = item.id;
      //         item.text = item.nama + ' - ' + item.satuan.satuan;
      //     });
      this.form2 = {
        id_barang: barang.id,
      };
      this.form = {
        nama: barang.nama,
        jenis: barang.jenis,
        id_kategori: barang.kategori ? barang.kategori.id : null,
        id_satuan: barang.satuan ? barang.satuan.id : null,
        harga_dasar: barang.harga_dasar,
        variants: [
          {
            kode: barang.kode,
            varian: barang.varian,
          },
        ],
      };
    }
  },
};
</script>
